import React from 'react'
import PrivateRoutes from '../Route'
import List from '../../pages/Commercial/Export/List'
import { CustomSwitch } from '../../components/CustomSwitch'

export const ExportRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes path="/commercial/export" exact component={List} />
  </CustomSwitch>
)
