import React, { useCallback, useEffect, useState } from 'react'
import Container from '../../../../components/Container'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import api from 'services/api'
import moment from 'moment'
import { useUpdateDataTable } from 'hooks/dataTable'
import { Loading } from 'components/Loading'
import { Date as DatePicker } from 'components/Form/date'
import { IMaskInput } from 'react-imask'
import { useToast } from 'hooks/toast'

const inputMaskFormat = 'DD/MM/YYYY'
const inputMaskFormatISO = 'YYYY-MM-DD'

const List: React.FC = () => {
  const { updateDataTable } = useUpdateDataTable()
  const [isLoading, setIsloading] = useState(false)
  const [startDate, setStartDate] = useState<Date>()
  const [endData, setEndDate] = useState<Date>()
  const { addToast } = useToast()

  const handleExport = useCallback(async () => {
    setIsloading(true)
    const start_date = moment(startDate).format(inputMaskFormatISO)
    const end_date = moment(endData).format(inputMaskFormatISO)
    const currentDateInTimestamp = moment().valueOf()
    try {
      const response = await api.post(
        '/commercial/prospectsQueriesApis/exportPerPeriod',
        {
          start_date,
          end_date
        }
      )
      const blob = new Blob(['\ufeff' + response.data], {
        type: 'text/csv;charset=utf-8"'
      })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.download = `${currentDateInTimestamp}-consultas-lemit-de-${start_date}-ate-${end_date}.csv`
      a.href = url
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    } catch (error: any) {
      addToast({
        title: 'Erro ao exportar',
        description:
          error?.response?.data?.message || 'Ocorreu um erro ao exportar',
        type: 'error'
      })
      console.log(error?.response?.data?.message)
    } finally {
      setIsloading(false)
      updateDataTable()
    }
  }, [endData, startDate, updateDataTable])

  if (isLoading) {
    return <Loading isActive />
  }

  return (
    <Container
      pageTitle="Listagem"
      breadcrumb={breadcrumbList}
      tools={toolsList}
    >
      <div className="row mb-5">
        <DatePicker
          name="start_date"
          className="col-2"
          label="Data inicial"
          placeholderText="DD/MM/AAAA"
          dateFormat="dd/MM/yyyy"
          selected={startDate}
          customInput={
            <IMaskInput
              mask={Date}
              pattern={'d/m/Y'}
              format={date => {
                return moment(date).format(inputMaskFormat)
              }}
              parse={value => {
                return moment(value, inputMaskFormat).toDate()
              }}
            />
          }
          onChange={date => {
            setStartDate(date)

            // if (endData) {
            //   if (moment(endData).diff(moment(date), 'month') > 2) {
            //     setEndDate(null)
            //   }
            // }
          }}
          controlled
        />
        <DatePicker
          name="end_date"
          className="col-2"
          label="Data final"
          placeholderText="DD/MM/AAAA"
          dateFormat="dd/MM/yyyy"
          selected={endData}
          maxDate={
            startDate ? moment(startDate).endOf('month').toDate() : undefined
          }
          customInput={
            <IMaskInput
              mask={Date}
              pattern={'d/m/Y'}
              format={date => {
                return moment(date).format(inputMaskFormat)
              }}
              parse={value => {
                return moment(value, inputMaskFormat).toDate()
              }}
            />
          }
          onChange={date => {
            setEndDate(date)

            // if (startDate) {
            //   if (moment(date, true).isValid()) {
            //     if (moment(startDate).diff(moment(date), 'month') > 2) {
            //       setStartDate(null)
            //     }
            //   }
            // }
          }}
          controlled
        />

        <div className="card-footer d-flex justify-content-end py-6 ps-9 pe-0">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleExport}
          >
            Exportar
          </button>
        </div>
      </div>
    </Container>
  )
}
export default List
